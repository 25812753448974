export default {
  init() {

$('.complete-link').click(function() {
    window.location = $(this).find('a').attr('href'); 
    return false;
  });
  


    // JavaScript to be fired on all pages
    function toggleAffix(affixElement) {
        if (jQuery(document).scrollTop() > 0) {
            affixElement.addClass('affix');
        }
        else {
            affixElement.removeClass('affix');
        }
    }


    jQuery('[data-toggle="affix"]').each(function () {
        var ele = jQuery(this);

        jQuery(window).on('scroll resize', function () {
            toggleAffix(ele);
        });
    });


    $('#nav-icon1,#nav-icon2,#nav-icon3,#nav-icon4').click(function () {
        $(this).toggleClass('open');
    });
    
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
